import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Redirector = () => {

    const navigate = useNavigate()
    useEffect(()=>{
        navigate('/home')
    })
  return (
    <div>Attendere prego...</div>
  )
}

export default Redirector