import React, { useState, useEffect } from 'react';
import AutocompleteSubMulti from './AutocompleteSubMulti'

const MultiAutoComplete = (props) => {

  const {apiSubModel,fieldName,initMultiValue,field,changeHandler,isValid,makeDisable} = props

  const [selectedUuids,setSelectedUuids] = useState('')
  const [multiValue,setMultiValue] = useState(initMultiValue.length?initMultiValue:[''])

  const arrayToString = (arr) => {
    let str = ''
    arr.forEach(
      (el,idx) => {
        if(idx===0){
          str+='['
        }
        if(idx===arr.length-1){
          str+=('"'+el+'"')
        } else{
          str+=('"'+el+'",')
        }
      }
    )
    str+=']'
    return str
  }

  useEffect(()=>{
    if(multiValue && Array.isArray(multiValue)){
      let selectedUuidsString = arrayToString(multiValue)
      setSelectedUuids(selectedUuidsString)
    }
  },[multiValue])

  const singleChangeHandler = (singleUuid,key) => {
    let newMultiValue = []
    for(let k in multiValue){
      newMultiValue.push(multiValue[k])
    }
    newMultiValue[key]=singleUuid
    setMultiValue(newMultiValue)
    changeHandler(arrayToString(newMultiValue),field)
  }

  const handleAdd = () => {
    let newMultiValue = []
    for(let k in multiValue){
      newMultiValue.push(multiValue[k])
    }
    newMultiValue[multiValue.length]=''
    setMultiValue(newMultiValue)
  }

  const handleRemove = () => {
    let newMultiValue = []
    for(let k in multiValue){
      newMultiValue.push(multiValue[k])
    }
    newMultiValue.pop()
    setMultiValue(newMultiValue)
    changeHandler(arrayToString(newMultiValue),field)
  }

  return (
    <>
      <div className={'container px-0' + (isValid ? '' : ' is-invalid')}>
        <input type="hidden" name={fieldName} value={selectedUuids} className='bg-lightgray form-control' readOnly />
        {
          multiValue && Array.isArray(multiValue) && multiValue.map((singleValue, idx) => {
            return <React.Fragment key={idx}>
              <AutocompleteSubMulti
                pos={idx}
                makeDisable={makeDisable}
                apiSubModel={apiSubModel}
                fieldName={apiSubModel}
                value={singleValue}
                autoCompleteClass={'form-control material-form-control' + (makeDisable?' custom-disabled':'')}
                field={field}
                singleChangeHandler={singleChangeHandler}
              />
              </React.Fragment>
          })
        }
        {!makeDisable && <div className='container px-0 text-end' style={{position: 'relative'}}>
          <div className='col-12 px-0'>
            {multiValue && multiValue.length > 1 && <button className='btn mcr-btn btn-micro-with-icon' title='Reset filtri' onClick={()=>{handleRemove()}}>
              <span className="material-symbols-rounded">remove</span>
            </button>}
            <button className='btn mcr-btn btn-micro-with-icon' title='Reset filtri' onClick={()=>{handleAdd()}}>
              <span className="material-symbols-rounded">add</span></button>
          </div>

          {!isValid && <span className="material-symbols-rounded down-left">error</span>}
            
          </div>}
        </div>
    </>
  )
}

export default MultiAutoComplete
