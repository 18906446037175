export const hiddenFields = ['uuid','winevalue_uuid','code','memo','parent_uuid','model','parent_model','createdby','committed','committedby','modified','modifiedby','deleted','deletedby','ownedby']
export const numberFields = ['number','integer','positivenumber','positiveinteger']
export const timeFields = ['date','datetime','time','timerange']
export const paginationOptions = [50,100,200,500,1000]
export const errorTable = [
    {code: 'E001', desc: 'Repository non disponibile'},
    {code: 'E101', desc: 'Errore nella sintassi della richiesta'},
    {code: 'E301', desc: 'Non hai i permessi di effettuare questa chiamata'},
    {code: 'E102', desc: 'Sono stati richiesti troppi oggetti'},
    {code: 'E103', desc: 'Oggetto non trovato'},
    {code: 'E104', desc: 'Formato non valido'},
    {code: 'E105', desc: 'Funzione non riconosciuta'},
    {code: 'E106', desc: 'Campo non riconosciuto'},
    {code: 'E107', desc: 'Valore campo non valido'},
    {code: 'E108', desc: 'Campo ordinamento non riconosciuto'},
    {code: 'E109', desc: 'Id oggetto non valido'},
    {code: 'E110', desc: 'Non è possibile committare due volte'},
    {code: 'E111', desc: 'Non è possibile cancellare due volte'},
    {code: 'E112', desc: 'Campo non riconosciuto al salvataggio'},
    {code: 'E113', desc: 'Campo non valido al salvataggio'},
    {code: 'E114', desc: 'Campo non modificabile al salvataggio'},
    {code: 'E115', desc: 'Campo obbligatorio non compilato al salvataggio'},
    {code: 'E116', desc: 'Campo univoco duplicato al salvataggio'}
]

export const get_error_message_by_code = (errCode) => {
    if(errCode.length === 4){
        for (let k in errorTable){
            if(errorTable[k].code === errCode){
                return errorTable[k].desc
            }
        }
    }
    return 'Errore sconosciuto, cod. '+errCode
}

export const get_final_ui_structure = (uiStructure,structureData) => {
    if(!uiStructure || typeof uiStructure !== 'object'){
        return structureData
    } else {
        let finalUIstructure = []
        for (let k in uiStructure){
          finalUIstructure.push({name: uiStructure[k].name, /* format: uiStructure[k].format, */ section: true})
          for(let j in uiStructure[k].fields){
            let fieldName = uiStructure[k].fields[j].field
            for (let f in structureData){
              if (structureData[f].name === fieldName){
                finalUIstructure.push({...structureData[f], format: uiStructure[k].format})
              }
            }
          }
        }
        return finalUIstructure
    }
}
export const get_html_field_type = (field) => {
    let server_type = field.type,
        server_name = field.name
    if (hiddenFields.includes(server_name)) return 'hidden'
    if (numberFields.includes(server_type)) return 'number'
    if (server_type==='boolean') return 'checkbox'
    if (server_type==='datetime') return 'datetime-local'
    if (server_type==='time'||server_type==='timerange') return 'time'
    if (server_type==='date') return 'date'
    return 'text' 
}

export const get_field_value = (field,objectData) => {
    if (field.name==='price' && !isNaN(objectData[field.name]) && objectData[field.name]!==''){
        return(Math.round(objectData[field.name]*100)/100)
    }
    if (timeFields.includes(field.type)){
        if (!isNaN(objectData[field.name]) && objectData[field.name]!==null) {
            let o = new Date(objectData[field.name]);
            return o.getFullYear()+'-'+(o.getMonth()<9?'0':'')+(1+o.getMonth())+'-'+(o.getDate()<10?'0':'')+o.getDate()+'T'+(o.getHours()<10?'0':'')+o.getHours()+':'+(o.getMinutes()<10?'0':'')+o.getMinutes();
        }
        return null
    }
    if(numberFields.includes(field.type)){
        if(isNaN(objectData[field.name]) || objectData[field.name]===null){
            return ''
        }
    }
    return objectData[field.name]
}

export const get_field_apimodel = (field, structureData) => {
    let strData = restore_backend_structure(structureData)
    return strData[field.name].model?strData[field.name].model:field.name
}

export const get_post_object_field_value = (field,value) => {
    if (field.type==='datetime'){
        if (value===null) return null;
        let o = new Date(value)
        return o.getTime()
    }
    if (field.type==='boolean'){
        return (value==='true')?false:true
    }
    if (field.type==='number'){
        if (!isNaN(value)){
            return parseFloat(value)
        } else {
            return null
        }
    }
    if(field.type==='integer'){
        if (!isNaN(value)){
          return Math.round(parseFloat(value))
        } else {
          return null
        }
    }
    if(field.type==='uuid[]'){
        if(value){
            try{
                let ar = JSON.parse(value)
                if (Array.isArray(ar)) return ar
                return null 
            } catch (error){
                console.log('Errore: un campo di tipo uuid[] ha ritornato un array non valido dopo la modifica')
            }
        } else {
          return null
        }
    }
    return value
}

export const check_field_validation = (structureData,objectData) => {
    let validated=[]
    for(let k in structureData){
        // List validation rules
        let required = structureData[k].required,
          min = structureData[k].min,
          max = structureData[k].max,
          // Check value against validation rules
          value = objectData[structureData[k].name],
          type = structureData[k].type,
          label = structureData[k].label ? structureData[k].label.charAt(0).toUpperCase()+structureData[k].label.slice(1) : structureData[k].name.charAt(0).toUpperCase()+structureData[k].name.slice(1)
        
          if((required && (value==='' || value === null || typeof value === 'undefined'))){
          validated.push({'name':structureData[k].name,'valid':false,'message':'Il campo '+label+' è richiesto'})
      
        } else if(max && value > max){
            validated.push({'name':structureData[k].name,'valid':false,'message':'Il valore massimo per il campo '+label+' è '+max})

        } else if (min && value < min){
            validated.push({'name':structureData[k].name,'valid':false,'message':'Il valore minimo per il campo '+label+' è '+min})

        } else if (type==='uuid' && (value==='' || value===null || typeof value === 'undefined') && required) {
            validated.push({'name':structureData[k].name,'valid':false,'message':'Il campo '+label+'+ è richiesto'})
        
        } else if (type==='uuid[]' && (value==='' || value===null || typeof value === 'undefined') && required){
            validated.push({'name':structureData[k].name,'valid':false,'message':'Il campo '+label+'+ è richiesto'})
        
        } else if (type==='uuid[]' && (typeof value === 'object') && required) {

            if(typeof value === 'object' && !value.length){
                validated.push({'name':structureData[k].name,'valid':false,'message':'Il campo '+label+'+ è richiesto'})
            }
            if(typeof value === 'object' && value.length){
                let validElement = false
                for(let k in value){
                    if(value[k]){
                        validElement = true
                    }
                }
                if(!validElement)validated.push({'name':structureData[k].name,'valid':false,'message':'Selezionare almeno un elemento nel campo '+label})
            }

        } else {
            validated.push({'name':structureData[k].name,'valid':true})
        }          
    }
    return validated
}

export const get_field_isvalid = (field,validation) => {
    for(let k in validation){
        if (validation[k].name === field.name && validation[k].valid===false){
            return false
        }
    }
    return true
}

// Returns given date string as a timestamp of start of day
const convert_html_field_date_to_timestamp = (dateString) => {
    let o = new Date(dateString)
    if(o) return o.getTime()
    return null
}

// Returns given date string as a timestamp of end of day
const convert_html_field_date_to_timestamp_endday = (dateString) => {
    let o = new Date(dateString)
    if(o) {
        o.setDate(o.getDate()+1)
        return o.getTime()
    }
    return null
}

const add_days_to_timestamp = (timeStamp,days) => {
    let o = new Date(timeStamp)
    if(o) {
        o.setDate(o.getDate()+days)
        return o.getTime()
    }
    return null
} 

const restore_backend_structure = (dataStructure) => {
    let aut = {}
    for (let k in dataStructure){
        aut[dataStructure[k].name]=dataStructure[k]
    }
    return aut
}

// This is just for single values, for intervals (even if artificially constructed) see 'get_backend_filter'
const get_backend_filter_value = (filterModelRow,dataStructure,name) => {
    let frontendFilterType=filterModelRow['type'],
        fields = restore_backend_structure(dataStructure) // To avoid the need to iterate through the entire object to find the correct field info
    
    if(numberFields.includes(fields[name].type) && filterModelRow['filter']) return parseFloat(filterModelRow['filter'])
    
    if(filterModelRow['filter']){
        if (frontendFilterType ==='eq' ) return filterModelRow['filter']
        if (frontendFilterType !=='eq' ) return filterModelRow['filter'].trim()
    }
    
    if(filterModelRow['dateFrom']){
        let jsTimeStampStartDay = convert_html_field_date_to_timestamp(filterModelRow['dateFrom'])
        if(frontendFilterType === 'gt' || frontendFilterType === 'lte'){
            let jsTimeStampEndDay = add_days_to_timestamp(jsTimeStampStartDay,1)
            return jsTimeStampEndDay
        }
        return jsTimeStampStartDay
    }

    if (filterModelRow['type']==='true')return true
    if (filterModelRow['type']==='false')return false
}

const get_backend_filter_function = (gridfunction) => {
    if(gridfunction==='contains_b')return 'contains'
    //if(gridfunction==='equals')return 'eq'
    //if(gridfunction==='greaterThan')return 'gt'
    //if(gridfunction==='lessThan')return 'lt'
    //if(gridfunction==='greaterThanOrEqual')return 'gte'
    //if(gridfunction==='greaterThanOrEqual')return 'lte'
    if(gridfunction==='true'||gridfunction==='false')return 'is'
    return gridfunction
}

export const get_backend_sort_model = (columnState) => {
    let sortBy={
        sortColumn: null,
        sortOrder: null
    }
    for(let k in columnState){
        if(columnState[k]['sort']==='asc'||columnState[k]['sort']==='desc'){
            sortBy = {
                sortColumn: columnState[k]['colId'],
                sortOrder: columnState[k]['sort']
            }
        }
    }
    return sortBy
}

export const get_backend_filter = (filterModel,sortModel,freeSearchString,dataStructure) => {
    
    let fields = []
    if(filterModel){
        for (let k in filterModel){
            // Se filtro range, costruire i between con due campi gte e lte per lo stesso field
            if(filterModel[k]['type']==='inRange_b'){
                let filterRowFrom = {
                    field: k,
                    value: filterModel[k]['dateFrom']?convert_html_field_date_to_timestamp(filterModel[k]['dateFrom']):filterModel[k]['filter'],
                    func: 'gte'
                }
                let filterRowTo = {
                    field: k,
                    value: filterModel[k]['dateTo']?convert_html_field_date_to_timestamp_endday(filterModel[k]['dateTo']):filterModel[k]['filterTo'],
                    func: 'lte'
                }
                fields.push(filterRowFrom)
                fields.push(filterRowTo)
            } else if (filterModel[k]['type']==='eq' && filterModel[k]['dateFrom']) {
                let filterRowFrom = {
                    field: k,
                    value: convert_html_field_date_to_timestamp(filterModel[k]['dateFrom']),
                    func: 'gte'
                }
                let filterRowTo = {
                    field: k,
                    value: convert_html_field_date_to_timestamp_endday(filterModel[k]['dateFrom']),
                    func: 'lte'
                }
                fields.push(filterRowFrom)
                fields.push(filterRowTo)
            } else {
            // Se filtro normale, costruire il normale campo a filtro singolo
                let filterRow = {
                    field: k,
                    value: get_backend_filter_value(filterModel[k],dataStructure,k),
                    func: get_backend_filter_function(filterModel[k]['type'])
                }
                fields.push(filterRow)
            }
        }
        
    }
    let sort = {}
    if (sortModel && sortModel.sortColumn && sortModel.sortOrder){
        sort={"field": sortModel.sortColumn, "desc": sortModel.sortOrder === 'desc'?true:false}
    }
    let bkf = {
        "query": freeSearchString,
        "format":"ui",
        "inputformat": "ui",
        "sort": sort,
        "offset": 0,
    }
    if(fields && fields.length){
        bkf.filter={
            "func": "and",
            "fields": fields
        }
    }
    return bkf
}

export const get_autocomplete_request_body = (searchString) => {
    return {
        "query": searchString,
        "format": "autocomplete",
        "sort": {
            "field": "memo"
        }
    }
}

export const get_autocomplete_onload_body = (value) => {
    return {
        "filter":{
            "func": "and",
            "fields": [
                {
                    "field": "uuid",
                    "value": value,
                    "func": "eq"
                }
            ]
        },
        "format": "autocomplete",
        "offset": 0,
        "count": false
    }
}

export const get_html_field_classname = (field,creating) => {
    if (get_html_field_type(field)==='datetime-local' && (field.name==='deleted')){
        return 'd-none' //'form-control'
    }
    if (get_html_field_type(field)==='datetime-local' && (field.name==='created' && creating)){
        return 'd-none' //'form-control'
    }
    if (get_html_field_type(field)==='checkbox'){
        return 'form-check-input mcr-checkbox d-block'
    }
    return 'form-control'
}

export const get_html_fieldlabel_classname = (field,creating) => {
    if (get_html_field_type(field,creating)==='hidden'){
        return 'd-none'
    }
    if (get_html_field_classname(field,creating) === 'd-none'){
        return 'd-none'
    }
    if(field.name==='uuid'){ // May be not hidden for index table use
        return 'd-none'
    }
    return 'form-label text-even mt-3'
}

export const get_checked = (field,objectData) => {
    let type=get_html_field_type(field),
        value=get_field_value(field,objectData)
    if(type==='checkbox'&&value===true)return true;
    return false;
}

export const convertTimestampToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleString('it-IT', options);
}

export const convertDateOnlyToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleString('it-IT', options);
}

export const convertTimeOnlyToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleString('it-IT', options);
}