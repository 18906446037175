import React from 'react'
import { useDispatch } from 'react-redux'
import { logIn, saveLoginInLocalStorage } from '../app/store'

const Login = () => {

    const dispatch = useDispatch()

    const handleLogin = () => {
        dispatch(logIn({hash: '12345'}))
        dispatch(saveLoginInLocalStorage())
    }

  return (
    <div className='' style={{marginTop: 0}}>
      <div className='hero d-flex justify-content-center align-items-center' style={{paddingBottom: 50}}>
        <button className='btn mcr-btn' onClick={handleLogin}>Login</button>
      </div>
    </div>
  )
}

export default Login