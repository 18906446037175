import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { get_autocomplete_request_body, get_autocomplete_onload_body } from '../helper.js'
import axios from 'axios';

const AutocompleteInput = (props) => {

    const {apiSubModel,value,field,changeHandler,autoCompleteClass,makeDisable} = props
    const [searchValue, setSearchValue] = useState('');
    const [selectedUuid, setSelectedUuid] = useState('');
    const [displayValue, setDisplayValue] = useState({'memo': ''});
    const [options, setOptions] = useState([]);
    const ref = useRef()

  useEffect(()=>{
    const fetchUuidOnLoad = async () => {
      const url = process.env.REACT_APP_LOCAL_BACKEND_URL + apiSubModel + '/search'
      const config = {
          headers: {'Authorization': '12345'},
          params: {}
      }
      if(value){
        let requestBody=get_autocomplete_onload_body(value)
        const response = await axios.post(url,requestBody,config)
        const recdata = response.data.result[0] // value is uuid
        if(recdata && recdata.uuid && recdata.memo){
          setSelectedUuid(recdata.uuid)
          setDisplayValue({'memo': recdata.memo})
        }
      }
    }
    setSelectedUuid(value)
    fetchUuidOnLoad()
  },[apiSubModel,value])

  const handleInputChange = async (event, newValue) => {
    if(event){
      setDisplayValue({'memo': newValue})
      if(newValue===''){
        setSelectedUuid('')
        changeHandler('',field)
      }
      if(newValue.length >= 3){
          try {
              setSearchValue(newValue);
              const url = process.env.REACT_APP_LOCAL_BACKEND_URL + apiSubModel + '/search'
              const config = {
                      headers: {'Authorization': '12345'},
                      params: {}
                  }
              let requestBody=get_autocomplete_request_body(searchValue)
              const response = await axios.post(url,requestBody,config)
              setOptions(response.data.result) // Assumendo che l'API restituisca un array di oggetti { id, displayname }
          } catch (error) {
              console.error('Errore nel recupero delle opzioni:', error);
          }
      }else{
        setOptions([])
      }
    }
  }

  const handleOptionSelect = (event, newValue) => {
    if (newValue) {
      setSelectedUuid(newValue.uuid)
      changeHandler(newValue.uuid,field)
    }
  };

  return (
    <div>
      <Autocomplete ref={ref}
        /* id={apiSubModel+'_autocomplete'} */
        options={options}
        getOptionLabel={(option) => option.memo?option.memo:''}
        value={displayValue}
        onChange={handleOptionSelect}
        onInputChange={handleInputChange}
        freeSolo={true}
        disabled={makeDisable}
        renderInput={(params) => (
          <TextField key={params.uuid} className={autoCompleteClass}
            {...params}
            placeholder="Cerca un elemento..."
            variant="standard"
          />
        )}
      />
      <input type="hidden" name={apiSubModel} value={selectedUuid} readOnly />
    </div>
  );
};

export default AutocompleteInput
